<section class="protfolio_breadcump" [ngStyle]="backGroundImage" *ngIf="backGroundImage">
  <div class="fondo">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="page-title-content">
            <h1>{{title}}</h1>
            <h1>{{smallTitle}}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>