import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProgramasRadioService } from 'src/app/servicios/difusion/programas-radio.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-audios',
  templateUrl: './audios.component.html',
  styleUrls: ['./audios.component.scss']
})
export class AudiosComponent implements OnInit {
  
  public cargandoDatos: boolean = true;
  public sinContenido: boolean = false;
  public MP3_HOST = environment.API_AUDIO;
  public idProgramaRadio;
  public programa: any = {};
  public audiosPrograma: any = [];
  public smallTitle;
  public title;
  public description;
  public imgPortada;

  constructor(
    private _router: ActivatedRoute,
    private programas_service: ProgramasRadioService
  ) {
    this._router.params.subscribe(res => this.idProgramaRadio = res.idProgramaRadio);
    this.title = 'AUDIOS';
    this.smallTitle = '';
    this.description = '';
    this.imgPortada = 'assets/img_codhey/poster.png'
  }

  ngOnInit() {
    this.obtenerPrograma(this.idProgramaRadio);
    this.obtenerAudios(this.idProgramaRadio);
  }

  obtenerPrograma(idPrograma) {
    this.programas_service.getPrograma(idPrograma).subscribe(respuesta => {
      this.programa = respuesta[0];
    })
  }

  obtenerAudios(idPrograma) {
    this.programas_service.getAudios(idPrograma).subscribe(respuesta => {
      this.audiosPrograma = respuesta;
      if (this.audiosPrograma) {
        this.sinContenido = false;
        this.cargandoDatos = false;
      } else {
        this.sinContenido = true;
        this.cargandoDatos = false;
      }
    })
  }
}