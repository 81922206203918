import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { AvisosPrivacidadService } from 'src/app/servicios/avisos-privacidad.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-avisosPrivacidad',
  templateUrl: './avisosPrivacidad.component.html',
  styleUrls: ['./avisosPrivacidad.component.scss']
})
export class AvisosPrivacidadComponent implements OnInit {

  public PDF_HOST = environment.API_PDF;
  public smallTitle;
  public title;
  public description;
  public imgPortada;
  public avisos: any = [];

  constructor(private viewportScroller: ViewportScroller, private avisos_service: AvisosPrivacidadService) {
    this.title = 'AVISOS DE PRIVACIDAD';
    this.smallTitle = '';
    this.description = '';
    this.imgPortada = 'assets/img_codhey/poster.png'
  }

  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
    this.obtenerAvisos();
  }

  obtenerAvisos() {
    this.avisos_service.getAvisos().subscribe(response => {
      this.avisos = response;
    });
  }

}
