import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BoletinesService } from 'src/app/servicios/difusion/boletines.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-detalleNota',
  templateUrl: './detalleNota.component.html',
  styleUrls: ['./detalleNota.component.scss']
})
export class DetalleNotaComponent implements OnInit {

  public idBoletin;
  public boletin: any = {};
  public imagenes: any = [];
  public PDF_HOST = environment.API_PDF;
  public IMG_HOST = environment.API_IMAGEN;
  public smallTitle;
  public title;
  public description;
  public imgPortada;
  public cargandoDatos: boolean = true;
  public sinContenido: boolean = false;

  constructor(
    private _router: ActivatedRoute,
    private boletines_service: BoletinesService
  ) {
    this._router.params.subscribe(res => this.idBoletin = res.idBoletin);
    this.title = '';
    this.smallTitle = '';
    this.description = '';
    this.imgPortada = 'assets/img_codhey/poster.png'
  }

  ngOnInit() {
    this.obtenerBoletin(this.idBoletin);
    this.obtenerImagenes(this.idBoletin);
  }

  homeSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    items: 1,
    autoHeight: true,
    smartSpeed: 750,
    autoplay: true,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>"
    ]
  }

  obtenerBoletin(idBoletin) {
    this.boletines_service.getBoletin(idBoletin).subscribe(respuesta => {
      this.boletin = respuesta[0];
      if (this.boletin) {
        this.sinContenido = false;
        this.cargandoDatos = false;
      } else {
        this.sinContenido = true;
        this.cargandoDatos = false;
      }
    });
  }

  obtenerImagenes(idBoletin) {
    this.boletines_service.getGaleria(idBoletin).subscribe(respuesta => {
      this.imagenes = respuesta;
    })
  }

}
