<app-encabezado-modulo [smallTitle]="smallTitle" [title]="title" [description]="description" [imgPortada]="imgPortada">
</app-encabezado-modulo>
<br><br>
<section class="work-area">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <button (click)="ngxSmartModalService.getModal('modalBienvenida').open()" type="button"
                    class="btn btn-primary">Bienvenida</button>
            </div>
            <div class="col-md-8 col-xs-12 col-sm-12">
                <div class="input-group flex-nowrap">
                    <input type="text" class="form-control" placeholder="Ingrese lo que desee encontrar"
                        aria-describedby="addon-wrapping" id="searchInput" name="search" [(ngModel)]="inputBusqueda"
                        (focus)="paginar = 1">
                    <span class="input-group-text" id="addon-wrapping"><i class="fas fa-search"></i></span>
                </div>
            </div>
        </div>
        <br>
        <div *ngIf="cargandoDatos">
            <i class="fa fa-spinner fa-spin fa-fw"></i> Cargando...
        </div>
        <div *ngIf="sinContenido && !cargandoDatos" style="text-align: center;">
            <h1><i class="fas fa-wrench"></i></h1>
            <h3>Sin contenido</h3>
        </div>
        <div *ngIf="!sinContenido && !cargandoDatos" class="row aling-items-center">

            <div class="row"
                *ngFor="let biblio of biblioteca| filter:inputBusqueda | paginate: { itemsPerPage: 15, currentPage: paginar, id: 'biblioteca' };   let i = index">
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <img [src]="IMG_HOST + biblio.rutaImagen" style="margin-bottom: 5%;">
                </div>
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                    <span style="margin-bottom: 8%;"> <b>TÍTULO:</b> {{biblio.titulo}}</span>
                    <br>
                    <span> <b>AUTOR:</b> {{biblio.autor}}</span>
                    <br><br>
                    <div [innerHtml]="biblio.descripcion | safeHtml">
                    </div>
                    <br>
                    <button class="button" *ngIf="biblio.rutaDocumento != ''">
                        <a [href]="PDF_HOST + biblio.rutaDocumento" target="_blank">
                            <span>
                                <i class="far fa-file-pdf icono"></i> Ver
                            </span>
                        </a>
                    </button>
                    <br>
                </div>
                <br>
                <hr>
                <br>
            </div>
            <br>
            <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                    <pagination-controls (pageChange)="paginar = $event" id="biblioteca" previousLabel="Anterior"
                        nextLabel="Siguiente">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="nuestrosDerechos-popup">
    <ngx-smart-modal #modalBienvenida [identifier]="'modalBienvenida'">
        <div class="modal-content">
            <div class="modal-header">
                <div class="section-titleN">
                    <h3>Bienvenidos a la Biblioteca de la codhey</h3>
                </div>
            </div>
            <div class="modal-body">
                <div class="about-content">
                    <div class="container">
                        <p>
                            La Comisión de Derechos Humanos del Estado de Yucatán pone a disposición el Centro de
                            Documentación y Biblioteca la cual cuenta con documentos impresos con el fin de satisfacer
                            su necesidad de información en el área de Derechos Humanos.
                        </p>
                        <p> Este apartado despliega información de la documentación impresa existente físicamente en la
                            biblioteca. Podrá realizar una consulta mediante el Título, el Autor o el Tema de su
                            interés.
                        </p>
                        <p>
                            Cabe aclarar que dicho material está disponible únicamente para su consulta en las
                            instalaciones de la CODHEY con un horario de atención de lunes a viernes de 8:00am a 3:00pm.
                        </p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" (click)="modalBienvenida.close()">Cerrar</button>
            </div>
        </div>
    </ngx-smart-modal>
</div>