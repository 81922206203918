import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ConvocatoriasService } from 'src/app/servicios/destacadas/convocatorias.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-convocatorias',
  templateUrl: './convocatorias.component.html',
  styleUrls: ['./convocatorias.component.scss']
})
export class ConvocatoriasComponent implements OnInit {

  public cargandoDatos: boolean = true;
  public sinContenido: boolean = false;
  public IMG_HOST = environment.API_IMAGEN;
  public PDF_HOST = environment.API_PDF;
  public convocatorias: any = [];
  public convocatoria: any = {};

  public smallTitle;
  public title;
  public description;
  public imgPortada;

  constructor(public ngxSmartModalService: NgxSmartModalService, public convocatorias_service: ConvocatoriasService) {
    /* Configuración del módulo */
    this.title = 'CONVOCATORIAS';
    this.smallTitle = '';
    this.description = '';
    this.imgPortada = 'assets/img_codhey/codheyf.jpg'
  }

  ngOnInit() {
    this.obtenerConvocatorias();
  }

  obtenerConvocatorias = () => {
    this.convocatorias_service.getConvocatorias().subscribe(respuesta => {
      this.convocatorias = respuesta;
      if (this.convocatorias) {
        this.sinContenido = false;
        this.cargandoDatos = false;
      } else {
        this.sinContenido = true;
        this.cargandoDatos = false;
      }
    })
  }

}