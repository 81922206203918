<app-encabezado-modulo [smallTitle]="smallTitle" [title]="title" [description]="description" [imgPortada]="imgPortada">
</app-encabezado-modulo>
<br><br>
<div class="blog-details-area" style="margin-top: -4%;">
    <div class="container">
        <div *ngIf="cargandoDatos">
            <i class="fa fa-spinner fa-spin fa-fw"></i> Cargando...
        </div>
        <div *ngIf="sinContenido && !cargandoDatos" style="text-align: center;">
            <h1><i class="fas fa-wrench"></i></h1>
            <h3>Sin contenido</h3>
        </div>
        <div *ngIf="!sinContenido && !cargandoDatos" class="row justify-content-center">

            <div class="col-lg-10">
                <h4 class="title" style="text-align: center; font-size: 40px;">
                    {{boletin.titulo}}
                </h4>
            </div>
            <br><br><br><br>
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <div class="home-slides">
                        <owl-carousel-o [options]="homeSlides">
                            <ng-template carouselSlide *ngFor="let imagen of imagenes">
                                <div class="main-banner-sede">
                                    <img [src]="IMG_HOST + imagen.rutaImagen" style="height: 100%;">
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                    <div class="article-text">
                        <!-- <br>
                        <h6 style="text-align: right;">
                            {{boletin.fecha | date:'dd/MM/yyyy'}}
                        </h6> -->
                        <div [innerHtml]="boletin.descripcion | safeHtml"></div>
                        <div class="author-share">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-6">
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <ul class="social-share">
                                        <li>
                                            <a [href]="PDF_HOST + boletin.rutaDocumento" tooltip="Abrir PDF" target="_blank"><i
                                                    class="far fa-file-pdf"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/sharer/sharer.php?u=&t="
                                                tooltip="Compartir en Facebook"
                                                onclick="window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(document.URL) + '&t=' + encodeURIComponent(document.URL)); return false;">
                                                <i class="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a class="w-inline-block social-share-btn tw"
                                                href="https://twitter.com/intent/tweet?" tooltip="Compartir en Twitter"
                                                onclick="window.open('https://twitter.com/intent/tweet?text=%20Check%20up%20this%20awesome%20content' + encodeURIComponent(document.title) + ':%20 ' + encodeURIComponent(document.URL)); return false;">
                                                <i class="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.whatsapp://send?text=" tooltip="Compartir en WhatsApp"
                                                onclick="window.open('https://api.whatsapp.com/send?text=' + encodeURIComponent(document.URL)); return false;">
                                                <i class="fab fa-whatsapp"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>