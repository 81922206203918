import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class BibliotecaService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getBiblioteca() {
    return this.http.get(`${this.API_URI}/biblioteca`)
  }

}
