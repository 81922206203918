import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/servicios/envio-email.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-formularioQueja',
  templateUrl: './formularioQueja.component.html',
  styleUrls: ['./formularioQueja.component.scss']
})
export class FormularioQuejaComponent implements OnInit {

  constructor(private envio_service: MessageService) { }

  ngOnInit() {
  }

  quejaForm(form) {
    var date = new Date();
    const formatDate = (date) => {
      let formatted_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      return formatted_date;
    }
    if (form.nombre != '' && form.descripcion != '' && form.telefono != '' && form.domicilio != '' && form.colonia != '' && form.municipio != '' && form.estado != '') {
      form.fecha = formatDate(date);
      this.envio_service.envioQueja(form).subscribe((response) => { });
      if (form) {
        this.envio_service.guardarQueja(form).subscribe((response) => {
          Swal.fire(
            '¡Hecho!',
            'Mensaje enviado',
            'success'
          )
          if (response) {
            setTimeout(
              function () { window.location.reload(); }, 3000
            );
          }
        })
      }
    } else (
      Swal.fire(
        'Verifica tus datos',
        '(Nombre. Domicilio. Colonia. Municipio. Estado. Teléfono. Descripción)',
        'error'
      )
    )
  }

}
