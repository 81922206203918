<app-encabezado-modulo [smallTitle]="smallTitle" [title]="title" [description]="description" [imgPortada]="imgPortada">
</app-encabezado-modulo>
<br><br>
<section class="work-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="article-text" [innerHtml]="contenidoSeccion.contenido | safeHtml">
            </div>
        </div>
        <br>
        <hr><br>
        <div class="row">
            <!-- <div class="col-md-9"></div> -->
            <div class="col-md-5">
                <a style="color:#FF8000" [routerLink]="['/acervo-biblioteca']" title="Visitar acervo bibliográfico">
                    <button type="button" class="btn btn-primary">
                        <i class="fas fa-link"></i>
                        Acervo bibliográfico
                    </button>
                </a>
            </div>
        </div>
    </div>
</section>