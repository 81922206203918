import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class ConcursosService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getConcursos() {
    return this.http.get(`${this.API_URI}/concursos`)
  }

  getConcurso(id: string) {
    return this.http.get(`${this.API_URI}/concursos/${id}`);
  }

  getGanadores(idAnio, idConcurso) {
    return this.http.get(`${this.API_URI}/concursos/ganadores/${idAnio}/${idConcurso}`);
  }

  getGaleria(id: string) {
    return this.http.get(`${this.API_URI}/concursos/galeria/${id}`);
  }


  getAniosConcursos(id: string) {
    return this.http.get(`${this.API_URI}/concursos/anios/${id}`);
  }

}
