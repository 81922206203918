<!-- <div *ngIf="menu" class="work-area pt-3 pb-70">
  <div class="container">
    <div class="visible-xs">
      <br>
      <ul class="trigger mb80 text-center">
        <li *ngFor="let m of menu; let i = index" [class.active]="m.route == lastPath" [routerLink]="[m.route]">
          {{m.text}}</li>
      </ul>
    </div>
  </div>
  <div>
    <div class="nav-menu hidden-xs">
      <div class="container">
        <div class="nav-menu-items">
          <div class="nav-item" [class.active]="m.route == lastPath" *ngFor="let m of menu; let i = index">
            <a [routerLink]="[m.route]">{{m.text}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div id="comision" class="work-area pt-3 ">
  <div class="container-fluid">
    <div class="menu-tabs">
      <div class="nav-tabset">
        <a *ngFor="let m of menu; let i = index" [routerLink]="[m.route]">
          <div class="nav-tab" [class.active]="m.route == lastPath" >
            <span>{{m.text}}</span>
          </div>
        </a>
      </div>
      <!-- <ngx-tabset>
        <ngx-tab [class.active]="m.route == lastPath" *ngFor="let m of menu; let i = index">
          <a [routerLink]="[m.route]">{{m.text}}</a>
        </ngx-tab>
      </ngx-tabset> -->
    </div>
  </div>
</div>