import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GaleriaFotograficaService } from 'src/app/servicios/difusion/galeria-fotografica.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-detalleGaleria',
  templateUrl: './detalleGaleria.component.html',
  styleUrls: ['./detalleGaleria.component.scss']
})
export class DetalleGaleriaComponent implements OnInit {

  public cargandoDatos: boolean = true;
  public sinContenido: boolean = false;
  public IMG_HOST = environment.API_IMAGEN;
  public idGaleria;
  public detalleGaleria: any = {};
  public imagenes: any = [];
  slideIndex = 1;
  public smallTitle;
  public title;
  public description;
  public imgPortada;

  constructor(
    private _router: ActivatedRoute,
    private galeria_service: GaleriaFotograficaService
  ) {
    this._router.params.subscribe(res => this.idGaleria = res.idGaleria);
    this.title = 'GALERÍA FOTOGRÁFICA';
    this.smallTitle = '';
    this.description = '';
    this.imgPortada = 'assets/img_codhey/poster.png'
  }

  ngOnInit() {
    this.obtenerDetalle(this.idGaleria);
    this.obtenerGaleria(this.idGaleria);
    this.showSlides(this.slideIndex);
  }

  obtenerDetalle(idGaleria) {
    this.galeria_service.getDetalleGaleria(idGaleria).subscribe(respuesta => {
      this.detalleGaleria = respuesta[0];
      if (this.detalleGaleria) {
        this.sinContenido = false;
        this.cargandoDatos = false;
      } else {
        this.sinContenido = true;
        this.cargandoDatos = false;
      }
    })
  }

  obtenerGaleria(idGaleria) {
    this.galeria_service.getImagenes(idGaleria).subscribe(respuesta => {
      this.imagenes = respuesta;
    })
  }

  // Next/previous controls
  plusSlides(n) {
    this.showSlides(this.slideIndex += n);
  }

  // Thumbnail image controls
  currentSlide(n) {
    this.showSlides(this.slideIndex = n);
  }

  showSlides(n) {
    let i;
    let slides: any = document.getElementsByClassName("mySlides");
    let dots: any = document.getElementsByClassName("demo");
    let captionText = document.getElementById("caption");
    if (n > slides.length) { this.slideIndex = 1 }
    if (n < 1) { this.slideIndex = slides.length }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace("active", "");
    }
    slides[this.slideIndex - 1].style.display = "block";
    dots[this.slideIndex - 1].className += " active";
    captionText.innerHTML = dots[this.slideIndex - 1].alt;
  }

}