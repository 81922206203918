import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// Import components to be shared
import { MenuModuloComponent } from './componentes/menu-modulo/menu-modulo.component';
import { EncabezadoModuloComponent } from './componentes/encabezado-modulo/encabezado-modulo.component';
import { SafeHtmlPipe } from './componentes/pipes/safeHtml.pipe';
import { TruncatePipe } from './componentes/pipes/truncate.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CursosComponent } from '../secciones/destacadas/cursos/cursos.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';

@NgModule({
  imports: [
    CommonModule, RouterModule, Ng2SearchPipeModule, NgxSmartModalModule
  ],
  declarations: [
    MenuModuloComponent,
    EncabezadoModuloComponent,
    SafeHtmlPipe,
    TruncatePipe,
    CursosComponent
  ],
  exports: [
    MenuModuloComponent,
    EncabezadoModuloComponent,
    CommonModule,
    SafeHtmlPipe,
    TruncatePipe,
    Ng2SearchPipeModule,
    CursosComponent
  ]
})

export class CompartidoModule { }