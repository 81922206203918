import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class BoletinesService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getBoletines() {
    return this.http.get(`${this.API_URI}/boletines`)
  }
  getBoletinesSlider() {
    return this.http.get(`${this.API_URI}/boletines/slider`)
  }

  getBoletin(id) {
    return this.http.get(`${this.API_URI}/boletines/${id}`);
  }

  getGaleria(id) {
    return this.http.get(`${this.API_URI}/boletines/galeria/${id}`);
  }

  // getSlider() {
  //   return this.http.get(`${this.API_URI}/boletines/slider`);
  // }

  getImagenPortada(id) {
    return this.http.get(`${this.API_URI}/boletines/imagenPortada/${id}`);
  }

  getImagenPortadaConvocatoria(id) {
    return this.http.get(`${this.API_URI}/boletines/imagenPortadaConvocatoria/${id}`);
  }

}
