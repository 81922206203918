import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConcursosService } from 'src/app/servicios/difusion/concursos.service';
import { environment } from 'src/environments/environment.prod';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-detalleConcurso',
  templateUrl: './detalleConcurso.component.html',
  styleUrls: ['./detalleConcurso.component.scss']
})
export class DetalleConcursoComponent implements OnInit {

  public idConcurso;
  public concurso: any = {};
  public anios;
  public ganadores: any = [];
  public imagenes: any = [];
  public PDF_HOST = environment.API_PDF;
  public IMG_HOST = environment.API_IMAGEN;
  public smallTitle;
  public title;
  public description;
  public imgPortada;
  public cargandoDatos: boolean = true;
  public sinContenido: boolean = false;
  public cargandoGanadores: boolean = true;
  public sinGanadores: boolean = false;
  slideIndex = 1;
  public galeria: any = [];



  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private _router: ActivatedRoute,
    private concurso_service: ConcursosService,
  ) {
    this._router.params.subscribe(res => this.idConcurso = res.idConcurso);
    this.title = 'CONCURSO';
    this.smallTitle = '';
    this.description = '';
    this.imgPortada = 'assets/img_codhey/poster.png'
  }

  ngOnInit() {
    this.obtenerConcurso(this.idConcurso);
    this.obtenerAnios();
    this.showSlides(this.slideIndex);

    // this.obtenerImagenes(this.idConcurso);
  }

  obtenerAnios() {
    this.concurso_service.getAniosConcursos(this.idConcurso).subscribe(response => {
      this.anios = response;
    })
  }

  obtenerConcurso(idConcurso) {
    this.concurso_service.getConcurso(idConcurso).subscribe(respuesta => {
      this.concurso = respuesta[0];
      if (this.concurso) {
        this.smallTitle = this.concurso.titulo;
        this.sinContenido = false;
        this.cargandoDatos = false;
      } else {
        this.sinContenido = true;
        this.cargandoDatos = false;
      }
    });
  }

  obtenerGanadores = (idAnio, idConcurso) => {
    this.concurso_service.getGanadores(idAnio, idConcurso).subscribe(response => {
      this.ganadores = response;
      if (this.ganadores) {
        this.sinGanadores = false;
        this.cargandoGanadores = false;
      } else {
        this.sinGanadores = true;
        this.cargandoGanadores = false;
      }
    });
  }

  abrirModal = (ganador) => {
    this.galeria = [];
    if (ganador) {
      const idGanador = ganador.idGanador;
      this.obtenerGaleria(ganador);
      this.ngxSmartModalService.getModal('galeriaGanador').open();
    }
  }

  obtenerGaleria(ganador) {
    this.concurso_service.getGaleria(ganador.idGanador).subscribe(response => {
      this.galeria = response;
      this.galeria.nombreGanador = ganador.nombreGanador;
      this.galeria.tipo = ganador.tipo;
    });
    this.ngxSmartModalService.setModalData(this.galeria, 'galeriaGanador');
  }

  // Next/previous controls
  plusSlides(n) {
    this.showSlides(this.slideIndex += n);
  }

  // Thumbnail image controls
  currentSlide(n) {
    this.showSlides(this.slideIndex = n);
  }

  showSlides(n) {
    let i;
    let slides: any = document.getElementsByClassName("mySlides");
    let dots: any = document.getElementsByClassName("demo");
    let captionText = document.getElementById("caption");
    if (n > slides.length) { this.slideIndex = 1 }
    if (n < 1) { this.slideIndex = slides.length }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace("active", "");
    }
    slides[this.slideIndex - 1].style.display = "block";
    dots[this.slideIndex - 1].className += " active";
    captionText.innerHTML = dots[this.slideIndex - 1].alt;
  }
}
