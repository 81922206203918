import { Component, OnInit } from '@angular/core';
import { ContenidoDHService } from 'src/app/servicios/derechosHumanos/contenidoDH.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-unidad-biblioteca',
  templateUrl: './unidadBiblioteca.component.html',
})
export class UnidadBibliotecaComponent implements OnInit {

  public cargandoDatos: boolean = true;
  public sinContenido: boolean = false;
  public IMG_HOST = environment.API_IMAGEN;
  public PDF_HOST = environment.API_PDF;
  public contenidoSeccion;

  public smallTitle;
  public title;
  public description;
  public imgPortada;

  constructor(
    private contenido_service: ContenidoDHService
  ) {
    /* Configuración del módulo */
    this.title = 'UNIDAD DE DOCUMENTACIÓN Y BIBLIOTECA ESPECIALIZADA EN DERECHOS HUMANOS';
    this.smallTitle = '"DIEGO GENARO CÓRTES ALPIZAR"';
    this.description = '';
    this.imgPortada = 'assets/img_codhey/codheyf.jpg'
  }

  ngOnInit() {
    this.obtenerBiblioteca();
  }

  obtenerBiblioteca = () => {
    this.contenido_service.getContenido('unidad_documentacion').subscribe(respuesta => {
      this.contenidoSeccion = respuesta[0];
      if (this.contenidoSeccion) {
        this.sinContenido = false;
        this.cargandoDatos = false;
      } else {
        this.sinContenido = true;
        this.cargandoDatos = false;
      }
    });
  }

}