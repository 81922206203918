<!-- <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav
    stickyClass="sticky-box-shadow" ngStickyNav> -->
<nav class="navbar navbar-expand-lg navbar-light " [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/img_codhey/codhey.png" style="height: 80px; ">
        </a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item" *ngFor="let m of menu">
                    <a [routerLink]="[m.ruta]">
                        <span class="nav-link">
                            {{m.nombreMenu}}
                        </span>
                    </a>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item" *ngFor="let m of menu">
                    <a [routerLink]="[m.ruta]">
                        <span class="nav-link">
                            {{m.nombreMenu}}
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>