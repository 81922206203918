<app-encabezado-modulo [smallTitle]="smallTitle" [title]="title" [description]="description" [imgPortada]="imgPortada">
</app-encabezado-modulo>
<br><br>
<section class="work-area">
    <div class="container">
        <div class="section-title">
            <h2>{{programa.titulo}}</h2>
        </div>
        <div *ngIf="cargandoDatos">
            <i class="fa fa-spinner fa-spin fa-fw"></i> Cargando...
        </div>
        <div *ngIf="sinContenido && !cargandoDatos" style="text-align: center;">
            <h1><i class="fas fa-wrench"></i></h1>
            <h3>Sin contenido</h3>
        </div>
        <div *ngIf="!sinContenido && !cargandoDatos" class="row">
            <div class="col-lg-6 col-md-6" *ngFor="let audio of audiosPrograma">
                <div class="recuadro-audios">
                    <div class="row">
                        <h6 style="text-align: right; margin-bottom: 10px;">
                            {{audio.fecha|date:'dd/MM/yyyy':"GMT+0"}}
                        </h6>
                    </div>
                    <div class="row">
                        <!-- <div class="col-md-2 icono">
                            <i class="fas fa-play"></i>
                        </div> -->
                        <div class="col-md-12">
                            <h6 style="width: 100%;"><b>{{audio.titulo}}</b></h6>
                            <hr>
                            <div style="width: 100%;" [innerHtml]="audio.descripcion | safeHtml"></div>
                        </div>
                    </div>
                    <div class="row" *ngIf="audio.rutaAudio !=''">
                        <br>
                        <audio controls height="100" width="100">
                            <source [src]="MP3_HOST + audio.rutaAudio">
                        </audio>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
