<app-encabezado-modulo [smallTitle]="smallTitle" [title]="title" [description]="description" [imgPortada]="imgPortada">
</app-encabezado-modulo>
<br><br>
<div class="blog-details-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <h4 class="title" style="text-align: center; font-size: 40px;">
                    {{curso.nombre}}
                </h4>
                <br>
                <div class="blog-details">
                    <br>
                    <div class="text-center">
                        <img [src]="IMG_HOST + curso.rutaImagen" class="rounded" alt="...">
                    </div>
                    <div class="article-text" [innerHtml]="curso.descripcion | safeHtml">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>