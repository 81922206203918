<footer class="footer-area">
    <div class="container">
        <h5><a routerLink="/"><span>Comisión de Derechos Humanos del Estado de Yucatán</span></a></h5>
        <ul>
            <li><a href="https://www.facebook.com/codhey"   class="fab fa-facebook-f"></a></li>
            <li><a href="https://twitter.com/codhey"   class="fab fa-twitter"></a></li>
            <li><a href="https://www.instagram.com/codheyoficial/"   class="fab fa-instagram"></a></li>
            <li><a href="https://open.spotify.com/show/1wVn0mR7lKiKkK85jISQHv"   class="fab fa-spotify"></a></li>
        </ul>
    </div>
    <div class="container-fluid">>
        <hr>
        <div class="row">
            <div class="col-md-4">
                <h6>Copyright <i class="far fa-copyright"></i>2022 All Rights Reserved.</h6>
            </div>
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <h6>
                    <a [routerLink]="['/avisos-privacidad']">
                        Avisos de privacidad
                    </a>
                </h6>
            </div>

        </div>
    </div>
</footer>
<ngx-scrolltop></ngx-scrolltop>