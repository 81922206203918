<app-encabezado-modulo [smallTitle]="smallTitle" [title]="title" [description]="description" [imgPortada]="imgPortada">
</app-encabezado-modulo>
<br><br>
<div id="avisos" class="welcome-area pt-3 pb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div *ngFor="let aviso of avisos" class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-box">
                    <p style="text-align: left;">{{aviso.nombre}}</p>
                    <hr>
                    <button class="button" *ngIf="aviso.rutaDocumento != ''">
                        <a [href]="PDF_HOST + aviso.rutaDocumento" target="_blank">
                            <span>
                                <i class="far fa-file-pdf icono"></i> Ver documento
                            </span>
                        </a>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>