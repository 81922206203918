import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ViewportScroller } from '@angular/common';

interface menu {
  text: string,
  route: string
}

@Component({
  selector: 'app-menu-modulo',
  templateUrl: './menu-modulo.component.html',
  styleUrls: ['./menu-modulo.component.scss']
})

export class MenuModuloComponent implements OnInit {

  @Input() menu: menu[];
  public lastPath: string;

  constructor(private router: Router, private viewportScroller: ViewportScroller) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let path = event.urlAfterRedirects.split('/');
        this.lastPath = path.pop();
      }
    })
  }

  public onClick(elementId: string): void { 
    this.viewportScroller.scrollToAnchor(elementId);
}

  ngOnInit() {
  }
}
