import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  public smallTitle;
  public title;
  public description;
  public imgPortada;

  constructor() {
    /* Configuración del módulo */
    this.title = 'CHAT-CODHEY';
    this.smallTitle = '';
    this.description = '';
    this.imgPortada = 'assets/img_codhey/codheyf.jpg'
  }

  ngOnInit() {
  }


}