import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class ContenidoDHService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getContenido(tabla) {
    return this.http.get(`${this.API_URI}/contenidos-DH/${tabla}`);
  }

}
