import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class CursosService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getDescripcionCurso(){
    return this.http.get(`${this.API_URI}/cursos/descripcion`)
  }

  getCursos() {
    return this.http.get(`${this.API_URI}/cursos`)
  }

  getCurso(id: string) {
    return this.http.get(`${this.API_URI}/cursos/${id}`);
  }

}
