import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-encabezado-modulo',
  templateUrl: './encabezado-modulo.component.html',
  styleUrls: ['./encabezado-modulo.component.css']
})


export class EncabezadoModuloComponent implements OnInit {
  @Input() smallTitle: string = '';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() imgPortada: string = '';

  public backGroundImage = {};
  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.backGroundImage = {
      'background-image': `url("/${this.imgPortada}")`
    }
    this.cdr.detectChanges();
  }

}
