<app-encabezado-modulo [smallTitle]="smallTitle" [title]="title" [description]="description" [imgPortada]="imgPortada">
</app-encabezado-modulo>
<br><br>
<section class="work-area">
    <div class="container">
        <div *ngIf="cargandoDatos">
            <i class="fa fa-spinner fa-spin fa-fw"></i> Cargando...
        </div>
        <div *ngIf="sinContenido && !cargandoDatos" style="text-align: center;">
            <h1><i class="fas fa-wrench"></i></h1>
            <h3>Sin contenido</h3>
        </div>
        <div *ngIf="!sinContenido && !cargandoDatos" class="row">
            <div class="col-lg-6 col-md-6" *ngFor="let convocatoria of convocatorias">
                <div class="single-services">
                    <div class="services-img">
                        <img [src]="IMG_HOST + convocatoria.rutaImagen">
                    </div>
                    <div class="services-content">
                        <h5>{{convocatoria.titulo}}</h5>
                        <br>
                        <div class="row">
                            <div *ngIf="convocatoria.url !=''" class="col">
                                <a [href]="convocatoria.url">
                                    <i class="fas fa-link icono"></i> Ver enlace
                                </a>
                            </div>
                            <div *ngIf="convocatoria.rutaBases !=''" class="col">
                                <a [href]="PDF_HOST + convocatoria.rutaBases" target="_blank">
                                    <i class="fas fa-book-reader icono"></i> Consulta las bases
                                </a>
                            </div>
                            <div class="col" *ngIf="convocatoria.rutaFichaRegistro !=''">
                                <a [href]="PDF_HOST + convocatoria.rutaFichaRegistro" target="_blank">
                                    <i class="fas fa-clipboard-list icono"></i> Ficha de registro
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>