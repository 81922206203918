import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class ProgramasRadioService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getProgramas() {
    return this.http.get(`${this.API_URI}/programas-radio`)
  }

  getPrograma(id) {
    return this.http.get(`${this.API_URI}/programas-radio/${id}`);
  }

  getAudios(id) {
    return this.http.get(`${this.API_URI}/programas-radio/audios/${id}`);
  }

}
