<app-encabezado-modulo [smallTitle]="smallTitle" [title]="title" [description]="description" [imgPortada]="imgPortada">
</app-encabezado-modulo>
<br><br>
<section class="work-area">
    <div *ngIf="cargandoDatos">
        <i class="fa fa-spinner fa-spin fa-fw"></i> Cargando...
    </div>
    <div *ngIf="sinContenido && !cargandoDatos" style="text-align: center;">
        <h1><i class="fas fa-wrench"></i></h1>
        <h3>Sin contenido</h3>
    </div>
    <div *ngIf="!sinContenido && !cargandoDatos" class="container">
        <div class="section-title">
            <h6>{{detalleGaleria.lugar}} {{detalleGaleria.fecha| date:'dd/MM/yyyy':"GMT+0"}} </h6>
            <br>
            <div [innerHtml]="detalleGaleria.descripcion | safeHtml"></div>
        </div>
        <div class="row">
            <div class="column" *ngFor="let foto of imagenes; let i = index">
                <img class="demo cursor" [src]="IMG_HOST + foto.rutaImagen" style="width:100%"
                    (click)="currentSlide(i+1)">
            </div>
        </div>
        <br>
        <div class="mySlides" *ngFor="let foto of imagenes; let i = index">
            <img [src]="IMG_HOST + foto.rutaImagen" style="width:100%">
        </div>
        <a class="prev" (click)="plusSlides(-1)">&#10094;</a>
        <a class="next" (click)="plusSlides(1)">&#10095;</a>
    </div>
</section>
