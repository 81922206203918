<app-encabezado-modulo [smallTitle]="smallTitle" [title]="title" [description]="description" [imgPortada]="imgPortada">
</app-encabezado-modulo>
<br><br>
<div class="blog-details-area">
    <div class="container">
        <div *ngIf="cargandoDatos">
            <i class="fa fa-spinner fa-spin fa-fw"></i> Cargando...
        </div>
        <div *ngIf="sinContenido && !cargandoDatos" style="text-align: center;">
            <h1><i class="fas fa-wrench"></i></h1>
            <h3>Sin contenido</h3>
        </div>
        <div *ngIf="!sinContenido && !cargandoDatos" class="row align-items-center">
            <!-- <div class="row">
                <h4 class="title" style="text-align: center; font-size: 40px;">
                    {{concurso.titulo}}
                </h4>
            </div>
            <br> -->
            <div class="col-lg-6 col-md-12">
                <div class="about-content" [innerHtml]="concurso.descripcion | safeHtml"></div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img [src]="IMG_HOST + concurso.rutaImagen">
                </div>
            </div>
        </div>
        <br><br>
        <div class="col-md-12">
            <div class="faq-accordion">
                <accordion>
                    <accordion-group *ngFor="let anio of anios"
                        (click)="obtenerGanadores(anio.idAnio, concurso.idConcurso)"
                        [heading]="'Ganadores ' + anio.fecha">
                        <div class="row justify-content-md-center">
                            <div class="col-lg-12">
                                <div *ngIf="cargandoGanadores">
                                    <i class="fa fa-spinner fa-spin fa-fw"></i> Cargando...
                                </div>
                                <div *ngIf="sinGanadores && !cargandoGanadores" style="text-align: center;">
                                    <h1><i class="fas fa-wrench"></i></h1>
                                    <h3>Sin contenido</h3>
                                </div>
                                <ol class="list-group list-group" *ngIf="!sinGanadores && !cargandoGanadores">
                                    <li *ngFor="let ganador of ganadores"
                                        class="list-group-item d-flex justify-content-between align-items-start">
                                        <div class="ms-2 me-auto">
                                            <div class="fw-bold">{{ganador.premio}}</div>
                                            <span><b>Nombre: </b> {{ganador.nombreGanador}}</span>
                                            <br>
                                            <span *ngIf="ganador.obra != ''"><b>Obra: </b> {{ganador.obra}}</span>
                                        </div>
                                        <button *ngIf="ganador.tipo == 0" class="button">
                                            <a [href]="PDF_HOST + ganador.rutaDocumento" target="_blank" >
                                                <span class="pdf">
                                                    <i class="far fa-file-pdf icono"></i> Ver obra
                                                </span>
                                            </a>
                                        </button>
                                        <br>
                                        <button *ngIf="ganador.tipo == 1" class="button">
                                            <a (click)="abrirModal(ganador)">
                                                <span class="pdf">
                                                    <i class="fas fa-photo-video icono"></i> Ver galería
                                                </span>
                                            </a>
                                        </button>
                                        <br>
                                        <button *ngIf="ganador.tipo == 2" class="button">
                                            <a (click)="abrirModal(ganador)">
                                                <span class="pdf">
                                                    <i class="fas fa-photo-video icono"></i> Ver foto
                                                </span>
                                            </a>
                                        </button>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </accordion-group>
                </accordion>
            </div>
        </div>

    </div>
</div>

<!-- MODAL GALERIA -->
<div class="nuestrosDerechos-popup">
    <ngx-smart-modal #galeriaGanador [identifier]="'galeriaGanador'">
        <div class="modal-content">
            <div *ngIf="galeriaGanador.hasData()">
                <div class="modal-header">
                    <div class="section-titleN">
                        <h3>{{galeria.nombreGanador}}</h3>
                    </div>
                </div>
                <div class="modal-body">
                    <div *ngIf="galeria.tipo == 1" class="container">
                        <!-- Thumbnail images -->
                        <div class="row">
                            <div class="col" *ngFor="let img of galeria; let i = index">
                                <img class="demo cursor" [src]="IMG_HOST + img.rutaImagen" style="width:100%"
                                    (click)="currentSlide(i+1)" [title]="img.titulo">
                            </div>
                        </div>
                        <br><br>
                        <!-- Full-width images with number text -->
                        <div class="mySlides" *ngFor="let imagen of galeria; let i = index">
                            <div class="numbertext">{{i+1}} / {{galeria.length}}</div>
                            <img [src]="IMG_HOST + imagen.rutaImagen" style="width:100%">
                        </div>
                        <!-- Next and previous buttons -->
                        <a class="prev" (click)="plusSlides(-1)">&#10094;</a>
                        <a class="next" (click)="plusSlides(1)">&#10095;</a>
                        <!-- Image text -->
                        <!-- <div class="caption-container">
                            <p id="caption"></p>
                        </div> -->

                    </div>
                    <div *ngIf="galeria.tipo == 2" class="container">


                        <div *ngFor="let imagen of galeria; let i = index">
                            <img [src]="IMG_HOST + imagen.rutaImagen" style="width:100%" [title]="imagen.titulo">
                        </div>



                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" (click)="galeriaGanador.close()">Cerrar</button>
            </div>
        </div>
    </ngx-smart-modal>
</div>