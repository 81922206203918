import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { BibliotecaService } from 'src/app/servicios/destacadas/biblioteca.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-biblioteca',
  templateUrl: './biblioteca.component.html',
  styleUrls: ['./biblioteca.component.scss']
})
export class BibliotecaComponent implements OnInit {

  public cargandoDatos: boolean = true;
  public sinContenido: boolean = false;
  public IMG_HOST = environment.API_IMAGEN;
  public PDF_HOST = environment.API_PDF;
  public biblioteca: any = [];
  public inputBusqueda: string = "";
  paginar: number = 1;

  public smallTitle;
  public title;
  public description;
  public imgPortada;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    public bilioteca_service: BibliotecaService
  ) {
    /* Configuración del módulo */
    this.title = 'ACERVO BIBLIOGRÁFICO';
    // this.smallTitle = '"DIEGO GENARO CÓRTES ALPIZAR"';
    this.description = '';
    this.imgPortada = 'assets/img_codhey/codheyf.jpg'
  }

  ngOnInit() {
    this.obtenerBiblioteca();
  }

  obtenerBiblioteca = () => {
    this.bilioteca_service.getBiblioteca().subscribe(respuesta => {
      this.biblioteca = respuesta;
      if (this.biblioteca) {
        this.sinContenido = false;
        this.cargandoDatos = false;
      } else {
        this.sinContenido = true;
        this.cargandoDatos = false;
      }
    })
  }

}