import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

export interface Menu {
    idMenu: string;
    nombreMenu: string;
    ruta: string;
}

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    menu: Menu[];

    constructor(private viewportScroller: ViewportScroller) { }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
        this.obtenerMenu();
    }

    private obtenerMenu() {
        this.menu = [
            {
                idMenu: '0',
                nombreMenu: 'INICIO',
                ruta: '/',
            },
            {
                idMenu: '1',
                nombreMenu: 'CODHEY',
                ruta: 'codhey',
            },
            {
                idMenu: '2',
                nombreMenu: 'DERECHOS HUMANOS',
                ruta: 'derechos-humanos',
            },
            {
                idMenu: '3',
                nombreMenu: 'RECOMENDACIONES',
                ruta: 'recomendaciones',
            },
            {
                idMenu: '4',
                nombreMenu: 'DIFUSIÓN',
                ruta: 'difusion',
            },
            {
                idMenu: '6',
                nombreMenu: 'MICROSITIOS',
                ruta: 'micro-sitios',
            },
            {
                idMenu: '5',
                nombreMenu: 'CONTACTO',
                ruta: 'contacto',
            },
        ]
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

}